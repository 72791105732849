import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { useAuthState } from '../../context/AuthContext';
import { useNewsContext } from '../../context/NewsContext';
import { fetchNews  } from '../../services/data.service'
import { toast } from 'react-toastify';
import { MAX_NEWS_LENGTH, NEWS_IMAGE_URL } from '../../config/Utilities';
import './News.css'
import moment from 'moment';

function News() {

    const {state,} = useAuthState();
    const {stateNews, type, dispatchNews} = useNewsContext();
    const navigate = useNavigate();
    const [currentCat, setCurrentCat] = useState("0")

    useEffect(() => {

        async function fetch(){
            //const data = await fetchNews((stateNews.newsList ? stateNews.newsList.length : 0) ,stateNews.category)
            const data = await fetchNews((stateNews.category === currentCat ? stateNews.newsList.length : 0) ,stateNews.category)
            dispatchNews({type: type.FETCH_LIST, payload: data.data});
            setCurrentCat(stateNews.category)
        }
        
        fetch()

    },[stateNews.category]);



    const filterByCategory = async(cuuid) => {
        console.log(cuuid)
        
        dispatchNews({type: type.SET_CATEGORY, payload: cuuid})

        //const data = await fetchNews((stateNews.newsList ? stateNews.newsList.length : 0) ,stateNews.category)
        //dispatchNews({type: type.FETCH_LIST, payload: data.data});
        
    }

    const appendList = () => {
        
        //fetchNews((stateNews.newsList ? stateNews.newsList.length : 0) ,stateNews.category)
        fetchNews((stateNews.category === currentCat ? stateNews.newsList.length : 0) ,stateNews.category)
        .then((res)=>{
            if(res.success){
                //setList([...list, ...res.data])
                dispatchNews({type: type.APPEND_LIST, payload: res.data})
               
            }else{
                toast.error(res.message);
            }
        });
        
        
    }

  return (
    <>
        <section className="py-6 position-relative">
        <div className="container">
            <div className="row justify-content-lg-between">
                <div className="col-lg-12">
                    <div className="d-flex align-items-center mb-5">
                        <h5 className="me-2 fw-medium">Төрөл:</h5>
                        <div>
                        <button type='button' className={"btn btn-sm mb-1 " +  (stateNews.category === "0" ? 'btn-primary':'btn-white')} onClick={() => { filterByCategory("0") }} key={"00a"}  >Бүгд</button>
                            {
                                stateNews.categories?.map((c,index) => {
                                    return <button type='button' className={"btn btn-sm mb-1 " +  (stateNews.category === c.uuid ? 'btn-primary':'btn-white')} onClick={() => { filterByCategory(c.uuid) }} key={index}  >{c.title}</button>
                                    
                                })
                            }
                            {/* <a className="btn btn-sm btn-white mb-1" href="#">Business</a>
                            <a className="btn btn-sm btn-white mb-1" href="#">Community</a>
                            <a className="btn btn-sm btn-white mb-1" href="#">Announcement</a>
                            <a className="btn btn-sm btn-white mb-1" href="#">Tutorials</a>
                            <a className="btn btn-sm btn-white mb-1" href="#">Resources</a>
                            <a className="btn btn-sm btn-white mb-1" href="#">Interview</a> */}
                        </div>
                    </div>
                </div>



                    <div className="row mt-6" data-aos="fade-up">
                        {
                            stateNews?.newsList.slice(0,1).map((news) => (
                                <div className="col-lg-8" key={news.uuid}>
                                    <div className="card shadow-none">
                                        <div className="row h-100">
                                            <div className="col-md-5 news-thumbnail">
                                                <img className="img-fluid rounded-sm img-thumb" src={NEWS_IMAGE_URL+news.image_source} alt="post image" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="card-body d-flex flex-column h-100 p-0 px-2">
                                                    <a className="" href={"/news/"+news.uuid}>
                                                        <span className="badge badge-soft-info mb-1">{news.category?.title}</span>
                                                    </a>

                                                    <h3 className="mt-1 fw-semibold">
                                                        <a className="" href={"/news/"+news.uuid}>
                                                            {news.title}
                                                        </a>
                                                    </h3>

                                                    <p className="text-muted">
                                                        {news.summary} <br/>
                                                        <a href={"/news/"+news.uuid} className="text-primary">Дэлгэрэнгүй</a>
                                                    </p>

                                                    <div className="mt-auto">
                                                        <div className="d-flex">
                                                            <img className="me-2 rounded-sm"
                                                                src={news.user?.image_source != null ? NEWS_IMAGE_URL+news.user?.image_source : "/assets/images/avatars/img-2.jpg"} alt="" height="36" />
                                                            <div className="flex-grow-1">
                                                                <h6 className="m-0 fs-13"><a href="">{news.user.first_name + "" +news.user.last_name}</a></h6>
                                                                <p className="text-muted mb-0 fs-13">{ moment(news.created_at).format("yyyy-MM-DD")} · 5 min read
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        
             
                        {
                            stateNews?.newsList.slice(1,2).map((news) => (
                                <div className="col-lg-4" key={news.uuid}>
                                    <div className="card card-listing-item">
                                        <div className="card-img-top-overlay">
                                            <div className="overlay"></div>
                                            <span className="card-badge top-right bg-danger text-white">{news.category?.title}</span>
                                            <img src={NEWS_IMAGE_URL+news.image_source}   alt="" className="card-img-top" />
            
                                            <div className="card-overlay-bottom">
                                                <h4 className="fw-semibold">
                                                    <a href={"/news/"+news.uuid} className="text-white">{news.title}</a>
                                                </h4>
            
                                                <div className="avatar-group mt-auto">
                                                    {
                                                        news.news_images.length > 0 ?
                                                        news.news_images.map((m, mi) => (
                                                            <a href="" className="avatar-group-item shadow-lg" key={m.news_id}>
                                                                <img src={NEWS_IMAGE_URL+m.image_source} alt="image" 
                                                                    className="img-fluid avatar-xs rounded rounded-circle avatar-border" />
                                                            </a>
                                                        ))
                                                        :
                                                        <>
                                                            <a href="" className="avatar-group-item shadow-lg">
                                                                <img src="/assets/images/avatars/img-7.jpg" alt="image"
                                                                    className="img-fluid avatar-xs rounded rounded-circle avatar-border" />
                                                            </a>
                                                            <a href="" className="avatar-group-item shadow-lg">
                                                                <img src="/assets/images/avatars/img-2.jpg" alt="image"
                                                                    className="img-fluid avatar-xs rounded rounded-circle avatar-border" />
                                                            </a>
                                                            <a href="" className="avatar-group-item shadow-lg">
                                                                <img src="/assets/images/avatars/img-4.jpg" alt="image"
                                                                    className="img-fluid avatar-xs rounded rounded-circle avatar-border" />
                                                            </a>
                                                        </>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            
                        }
                    </div>
                 

                    
                     <div className="row mt-6" data-aos="fade-up">
                        {
                            stateNews?.newsList.slice(2,stateNews?.newsList.length).map((news) => (
                                <div className="col-lg-4" key={news.uuid}>
                                    <div className='news-thumbnail'>
                                        <img src={NEWS_IMAGE_URL+news.image_source} alt="crypto"
                                            className="img-fluid shadow rounded img-thumb" />

                                        <div className="mt-3">
                                            <a href={"/news/"+news.uuid}>
                                                <span className="badge badge-soft-info mb-1">{news.category?.title}</span>
                                            </a>
                                        </div>

                                        <h4 className="fw-semibold mt-1">
                                            <a className="" href={"/news/"+news.uuid}>
                                                {news.title}
                                            </a>
                                        </h4>

                                        <p className="text-muted">
                                            {news.summary} <br/>
                                            <a href={"/news/"+news.uuid} className="text-primary">Дэлгэрэнгүй</a>
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                   

                 
          
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-center">
                                {
                                    stateNews.newsList?.length >= MAX_NEWS_LENGTH
                                    ?
                                    <button className='btn btn-white btn-sm' type='button' onClick={appendList}><i
                                        className="icon icon-xxs icon-down-arrow me-2"></i>Өмнөх мэдээлэл</button>
                                    :
                                    ""
                                }
                                
                                {/* <a className="btn btn-sm btn-white" href="#"><i
                                        className="icon icon-xxs icon-left-arrow me-2"></i>Previous</a>
                                <a className="btn btn-sm btn-white ms-2" href="#">Next<i
                                        className="icon-xxs icon-right-arrow ms-2"></i></a> */}
                            </div>
                        </div>
                    </div>
                    
               
            </div>
        </div>
    </section>
    </>
  )
}

export default News